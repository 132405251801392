import { createSlice } from "@reduxjs/toolkit"
import { getAnnouncements, sendAnnouncement } from "./api"

export const announcementsSlice = createSlice({
    name: 'announcements',
    initialState: {
        announcements: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(sendAnnouncement.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(sendAnnouncement.fulfilled, (state, action) => {
                state.isLoading = false
                state.announcements.push(action.payload)
            })
            .addCase(sendAnnouncement.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(getAnnouncements.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(getAnnouncements.fulfilled, (state, action) => {
                state.isLoading = false
                state.announcements = action.payload
            })
            .addCase(getAnnouncements.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    }
})

export default announcementsSlice.reducer