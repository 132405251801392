import { createSlice } from "@reduxjs/toolkit"
import { addProductsCounting, getProductsCounting } from "./api"

export const productCountingSlice = createSlice({
    name: 'productCounting',
    initialState: {
        countingList: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProductsCounting.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(getProductsCounting.fulfilled, (state, action) => {
                state.isLoading = false
                state.countingList = action.payload
            })
            .addCase(getProductsCounting.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(addProductsCounting.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(addProductsCounting.fulfilled, (state, action) => {
                state.isLoading = false
                state.countingList.push(action.payload)
            })
            .addCase(addProductsCounting.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    }
})

export default productCountingSlice.reducer