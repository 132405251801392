import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from './features/authSlice'
import issueRecordsReducer from './features/issueRecordsSlice'
import holidaysReducer from './features/holidaysSlice'
import rosterReducer from './features/rosterSlice'
import usersReducer from './features/usersSlice'
import groupsReducer from './features/groupsSlice'
import productsReducer from './features/productsSlice'
import productCountingReducer from './features/productCountingSlice'
import trainingReducer from './features/trainingSlice'
import announcementsReducer from './features/announcementsSlice'

const rootReducer = combineReducers({
    auth: authReducer,
    issueRecords: issueRecordsReducer,
    holidays: holidaysReducer,
    roster: rosterReducer,
    users: usersReducer,
    groups: groupsReducer,
    products: productsReducer,
    productCounting: productCountingReducer,
    training: trainingReducer,
    announcements: announcementsReducer,
})

export const store = configureStore({
    reducer: rootReducer
})