import { Add } from '@mui/icons-material'
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getProductsCounting } from '../../redux/features/api'

const Counting = () => {
  const formattedDate = (row) => {
    const dateObj = new Date(row);
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { countingList, isLoading } = useSelector((state) => state.productCounting)
  useEffect(() => {
    dispatch(getProductsCounting())
  }, [dispatch])
  // select group
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { groups } = userData;
  const [selectGroup, setSelectGroup] = useState('')
  const [filteredCountingList, setFilteredCountingList] = useState([])
  const selectProperty = (e) => {
    setSelectGroup(e.target.value)
    const filtered = countingList.filter((item) => item.branch === e.target.value)
    setFilteredCountingList(filtered)
  }

  const handleAddNew = () => {
    if (selectGroup) {
      navigate('/dashboard/countingnew', { state: { group: selectGroup } })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select a property!',
      })
    }
  }

  const handleRowClick = (row) => {
    navigate(`/dashboard/counting/${row.id}`)
  }

  // datagrid config
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })
  const columns = [
    {
      flex: 0.3,
      // width: 300,
      field: 'date',
      headerName: 'Counting Date',
      renderCell: ({ row }) => (
        <Stack direction="row">
          <Typography variant="subtitle2">{formattedDate(row.date)}</Typography>
        </Stack>
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      flex: 0.2,
      // minWidth: 200,
      field: 'total_products',
      headerName: 'Total Product',
      renderCell: ({ row }) => (
        <Stack direction="row">
          <Typography variant="subtitle2" textTransform={'capitalize'}>
            {row.total_products}
          </Typography>
        </Stack>
      ),
      headerClassName: 'super-app-theme--header',
      align: 'right',
      headerAlign: 'right',
    },
    {
      flex: 0.2,
      // minWidth: 200,
      field: 'total',
      headerName: 'Total Amount',
      renderCell: ({ row }) => (
        row.price
      ),
      headerClassName: 'super-app-theme--header',
      align: 'right',
      headerAlign: 'right',
    },

  ]
  return (
    <>
      <Helmet>
        <title>Counting List</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Counting List
          </Typography>
          <Button onClick={handleAddNew} variant="contained" startIcon={<Add />}>
            Add New Counting
          </Button>
        </Stack>
        <FormControl sx={{ m: 1, width: "100%" }}>
          <InputLabel id="issueType-label">Property</InputLabel>
          <Select
            labelId="issueType-label"
            id="issueType"
            label="Issue Type"
            value={selectGroup}
            onChange={(e) => selectProperty(e)}
          >
            <MenuItem value="" selected disabled ><em>Select</em></MenuItem>
            {
              groups.map((group, i) => (
                <MenuItem key={i} value={group.id}>{group.name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <Box
          sx={{
            height: 300,
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: 'lightgray',
            },
          }}
        >
          <DataGrid
            autoHeight
            rowHeight={50}
            loading={isLoading}
            rows={filteredCountingList ?? []}
            columns={columns}
            pageSizeOptions={[10, 25, 50]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            disableRowSelectionOnClick
            disableColumnSelector
            disableDensitySelector
            onRowClick={handleRowClick}
            // add quick search
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>
      </Container>
    </>
  )
}

export default Counting