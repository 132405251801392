import { createSlice } from "@reduxjs/toolkit"
import { addIssueRecord, getIssueRecords } from "./api"

export const issueRecordsSlice = createSlice({
    name: 'issueRecords',
    initialState: {
        issueRecords: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getIssueRecords.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(getIssueRecords.fulfilled, (state, action) => {
                state.isLoading = false
                state.issueRecords = action.payload
            })
            .addCase(getIssueRecords.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(addIssueRecord.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(addIssueRecord.fulfilled, (state, action) => {
                state.isLoading = false
                state.issueRecords.push(action.payload)
            })
            .addCase(addIssueRecord.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    }
})

export default issueRecordsSlice.reducer