import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
// import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import IssueRecords from './pages/IssueRecords';
// import IssueRecordNew from './pages/IssueRecordNew';
import IssueDetail from './pages/IssueDetail';
import Roster from './pages/Roster';
import Users from './pages/Users/Users';
import Products from './pages/Stock/Products';
import Counting from './pages/Stock/Counting';
import CountingNew from './pages/Stock/CountingNew';
import CountingDetail from './pages/Stock/CountingDetail';
import Announcement from './pages/Ann/Announcement';
import Holidays from './pages/Holidays';
import TrainingMain from './pages/Training/TrainingMain';
import TrainingDetail from './pages/Training/TrainingDetail';
import AnnList from './pages/Ann/AnnList';
import AnnDetail from './pages/Ann/AnnDetail';

export default function Router() {
  const location = useLocation();
  const userData = localStorage.getItem('userData');
  const rosterCreater = JSON.parse(userData)?.roster_creater ?? false;
  const isStaff = JSON.parse(userData)?.is_staff ?? false;
  const isSuperuser = JSON.parse(userData)?.is_superuser ?? false;
  const userRole = JSON.parse(userData)?.role ?? false;
const isManager = userRole === 'manager';
  const redirectToLogin = () => {
    if (!userData && location.pathname !== '/login') {
      return <Navigate to="/login" />;
    }
    return null;

  };

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: redirectToLogin() || <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        (isSuperuser || isManager || isStaff) && { path: 'holidays', element: <Holidays /> },
        (isSuperuser || rosterCreater || isStaff) && { path: 'roster', element: <Roster /> },
        (isSuperuser || isStaff) && { path: 'users', element: <Users /> },
        (isSuperuser || isStaff) && { path: 'products', element: <Products /> },
        (isSuperuser || isStaff) && { path: 'counting', element: <Counting /> },
        (isSuperuser || isStaff) && { path: 'counting/:id', element: <CountingDetail /> },
        (isSuperuser || isStaff) && { path: 'countingnew', element: <CountingNew /> },
        (isSuperuser || isStaff) && { path: 'announcements', element: <AnnList /> },
        (isSuperuser || isStaff) && { path: 'announcements/new', element: <Announcement /> },
        (isSuperuser || isStaff) && { path: 'announcements/:id', element: <AnnDetail /> },
        (isSuperuser || isStaff) && { path: 'training', element: <TrainingMain /> },
        (isSuperuser || isStaff) && { path: 'training/:id', element: <TrainingDetail /> },
        // { path: 'user', element: <UserPage /> },
        { path: 'issues', element: <IssueRecords /> },
        { path: 'issues/:id', element: <IssueDetail />},
        // { path: 'newissue', element: <IssueRecordNew /> },
      ],
    },
    {
      path: 'login',
      element: userData ? <Navigate to="/dashboard/app" /> : <LoginPage />,
    },
    {
      element: redirectToLogin() || <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
