import { Box, Button, Checkbox, Container, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Add, CloudUpload, Save } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { addTraining, getGroups, getTraining } from '../../redux/features/api';

const TrainingMain = () => {
    const formattedDate = (row) => {
        const dateObj = new Date(row);
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear();
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { training, isLoading } = useSelector(state => state.training)
    useEffect(() => {
        dispatch(getTraining())
    }, [dispatch])

    // add new training
    const [open, setOpen] = useState(false);

    // property section
    useEffect(() => {
        dispatch(getGroups())
    }, [dispatch])
    const [branchName, setBranchName] = useState(null);
    const [positionName, setPositionName] = useState(null);
    const groups = useSelector((state) => state.groups.groups)
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        if (value.length === 0) { setBranchName(null) }
        setBranchName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        // setValue('branch', branchName)
    };

    const handleChangePosition = (event) => {
        const {
            target: { value },
        } = event;
        if (value.length === 0) { setPositionName(null) }
        setPositionName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        // setValue('branch', branchName)
    }

    const userRoles = [
        { id: 'housekeeper', name: 'Housekeeper' },
        { id: 'receptionist', name: 'Receptionist' },
        { id: 'maintenancer', name: 'Maintenancer' },
        { id: 'nightporter', name: 'Nightporter' },
        { id: 'manager', name: 'Manager' },
    ]

    const SwalWarning = (desc) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: desc,
        })
    }

    // modal section
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 44,
        borderRadius: 2,
        p: 4,
    };
    const validationSchema = yup.object().shape({
        title: yup.string().required('Title is required'),
        file: yup.string().required('File is required'),
        position: yup.string().required('Position is required'),
    });
    const { handleSubmit, formState: { errors }, control, reset, setValue, getValues } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            title: '',
            file: '',
            position: '',
        },
    });

    // list of properties
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })

    const columns = [
        {
            flex: 0.3,
            // width: 300,
            field: 'title',
            headerName: 'Title',
            renderCell: ({ row }) => (
                <Stack direction="row">
                    <Typography variant="subtitle2">{row.title}</Typography>
                </Stack>
            ),
            headerClassName: 'super-app-theme--header',
        },
        {
            flex: 0.2,
            // minWidth: 200,
            field: 'branch',
            headerName: 'Properties',
            renderCell: ({ row }) => {
                // convert string to array
                const branch = row.branch.replace(/[[\]']+/g, '');
                const branches = branch.split(',');
                return (
                    <Stack direction="column">
                        {branches.map((item, index) => (
                            <Typography key={index} variant="subtitle2">{item}</Typography>
                        ))}
                    </Stack>
                )
            },
            headerClassName: 'super-app-theme--header',
        },
        {
            flex: 0.2,
            // minWidth: 200,
            field: 'position',
            headerName: 'Position',
            renderCell: ({ row }) => {
                // convert string to array
                const position = row.position.replace(/[[\]']+/g, '');
                const positions = position.split(',');
                return (
                    <Stack direction="column">
                        {positions.map((item, index) => (
                            <Typography textTransform={'capitalize'} key={index} variant="subtitle2">{item}</Typography>
                        ))}
                    </Stack>
                )
            },
            headerClassName: 'super-app-theme--header',
        },
        {
            flex: 0.2,
            // minWidth: 200,
            field: 'date',
            headerName: 'Date',
            renderCell: ({ row }) => (
                <Stack direction="row">
                    <Typography variant="subtitle2" textTransform={'capitalize'}>
                        {formattedDate(row.date)}
                    </Typography>
                </Stack>
            ),
            headerClassName: 'super-app-theme--header',
        },

    ]
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
        reset();
    }

    const handleAddNew = () => {
        // data.branch = selectGroup
        const title = getValues('title')
        const file = fileInputRef.current.files[0]
        const data = {
            title,
            file,
            position: positionName,
            branch: branchName,
        }
        if (title === '') {
            SwalWarning('Title is required')
        } else if (file === undefined) {
            SwalWarning('File is required')
        } else if (positionName === null) {
            SwalWarning('Position is required')
        } else if (branchName === null) {
            SwalWarning('Property is required')
        } else {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                // cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!',
                showLoaderOnConfirm: isLoading,
                preConfirm: () => new Promise(() => {
                    dispatch(addTraining(data))
                        .then((res) => {
                            const { id } = res.payload;
                            if (!res.error) {
                                Swal.update({
                                    title: 'Success',
                                    text: 'Your file has been sent!',
                                    icon: 'success',
                                    allowOutsideClick: true,
                                    allowEscapeKey: true,
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                });
                                navigate(`/dashboard/training/${id}`)
                            } else {
                                Swal.showValidationMessage(res.error.message); // Hata mesajı göster
                            }
                        })
                }),
            });
        }

        // dispatch(addTraining(data))
        //     .then((res) => {
        //         if (!res.error) {
        //             Swal.fire({
        //                 icon: 'success',
        //                 title: 'Success',
        //                 text: 'Training has been added!',
        //             })
        //             handleClose()
        //         } else {
        //             Swal.fire({
        //                 icon: 'error',
        //                 title: 'Oops...',
        //                 text: res.error.message,
        //             })
        //         }
        //     })

    }
    const fileInputRef = useRef(null);

    // useEffect(() => {
    //     const filter = training.filter((item) => item.branch === selectGroup)
    //     setFiltered(filter)
    // }, [training, selectGroup])

    const handleRowClick = (e) => {
        navigate(`/dashboard/training/${e.row.id}`)
    }

    return (
        <>
            <Helmet>
                <title>Training</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Training List
                    </Typography>
                    <Button onClick={handleOpen} variant="contained" startIcon={<Add />}>
                        Add New
                    </Button>
                </Stack>
                <Box
                    sx={{
                        height: 300,
                        width: '100%',
                        '& .super-app-theme--header': {
                            backgroundColor: 'lightgray',
                        },
                    }}
                >
                    <DataGrid
                        autoHeight
                        getRowHeight={() => 'auto'}
                        loading={isLoading}
                        rows={training}
                        columns={columns}
                        pageSizeOptions={[10, 25, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        disableRowSelectionOnClick
                        disableColumnSelector
                        disableDensitySelector
                        onRowClick={handleRowClick}
                    />
                </Box>
            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                    zIndex: 10,
                }}
            >
                <Box sx={style}>
                    <Typography mb={2} id="modal-modal-title" variant="h6" component="h2">
                        Add New Training
                    </Typography>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <Controller
                                name="title"
                                control={control}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        label="Title"
                                        error={Boolean(errors.title)}
                                        helperText={errors.title?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Stack>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-multiple-checkbox-label">Position</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                multiple
                                value={positionName || []}
                                onChange={handleChangePosition}
                                input={<OutlinedInput label="Position" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {userRoles.sort((a, b) => a.id.localeCompare(b.id)).map((item, i) => (
                                    <MenuItem key={i} value={item.name}>
                                        <Checkbox checked={positionName?.indexOf(item.name) > -1} />
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>

                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-multiple-checkbox-label">Property</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                multiple
                                value={branchName || []}
                                onChange={handleChange}
                                input={<OutlinedInput label="Property" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {groups.map((item, i) => (
                                    <MenuItem key={i} value={item.name}>
                                        <Checkbox checked={branchName?.indexOf(item.name) > -1} />
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <Button
                                component="label"
                                variant="contained"
                                startIcon={<CloudUpload />}
                            >
                                <Controller
                                    name="file"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="file"
                                            accept=".pdf"
                                            ref={fileInputRef}
                                        />
                                    )}
                                />
                            </Button>
                            {/* formcontrol error */}
                            <FormHelperText error>
                                {errors.file?.message}
                            </FormHelperText>
                        </FormControl>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={2}>
                        <Button onClick={handleClose} variant="outlined" sx={{ mr: 1 }}>
                            Cancel
                        </Button>
                        <Button onClick={() => handleAddNew()} variant="contained" startIcon={<Save />}>
                            Save
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default TrainingMain