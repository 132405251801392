import { Cancel, Circle, Done, Loop, Pending } from "@mui/icons-material";
import { Container, ImageList, List, ListItem, ListItemText, Paper, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { apiURL } from "../redux/config";

export default function IssueDetail() {
    const { state: { issue } } = useLocation();

    const formattedDate = (row) => {
        const dateObj = new Date(row);
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear();
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
    const priorityColor = {
        'high': 'error',
        'medium': 'warning',
        'low': 'success',
    }
    const TYPE_CHOICES = {
        'general': 'General',
        'heating': 'Heating',
        'plumbing': 'Plumbing',
        'electrical': 'Electrical',
        'carpentry': 'Carpentry',
        'painting': 'Painting',
        'appliance': 'Appliance',
        'other': 'Other',
    }
    const statusColor = {
        pending: '#fbbf24',
        in_progress: '#38bdf8',
        resolved: '#4ade80',
        cancelled: '#a1a1aa',
    };

    const STATUS_CHOICES = {
        pending: 'Pending',
        in_progress: 'In Progress',
        resolved: 'Resolved',
        cancelled: 'Cancelled',
    };

    const statusIcon = {
        pending: <Pending />,
        in_progress: <Loop />,
        resolved: <Done />,
        cancelled: <Cancel />,
    };

    return (
        <>
            <Helmet>
                <title>Issue Detail</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Issue Detail
                    </Typography>
                </Stack>
                <Paper sx={{
                    p: 2,
                    width: '100%',
                    backgroundColor: statusColor[issue.status],
                    textAlign: 'center',
                }} elevation={3}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        {statusIcon[issue.status]} {STATUS_CHOICES[issue.status]}
                    </Stack>
                </Paper>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Paper sx={{ p: 2, width: '100%' }} elevation={3}>
                        <Typography variant="h6" gutterBottom>
                            <Stack direction="row" alignItems="center" justifyContent="flex-start">
                                Title: {issue.title} <Circle sx={{ ml: 1 }} color={priorityColor[issue.priority]} />
                            </Stack>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Property: {issue.branch}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Type: {TYPE_CHOICES[issue.type]}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Date: {formattedDate(issue.date)}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Created By: {issue.created_by}
                        </Typography>
                        {
                            issue.assigned_to &&
                            <>
                                <Typography variant="body1" gutterBottom>
                                    Assigned To: {issue.assigned_name}
                                </Typography><Typography variant="body1" gutterBottom>
                                    Assigned Date: {formattedDate(issue.assigned_date)}
                                </Typography>
                            </>

                        }
                        {
                            issue.status === 'resolved' &&
                            <>
                                <Typography variant="body1" gutterBottom>
                                    Resolved Date: {formattedDate(issue.resolved_date)}
                                </Typography>
                            </>
                        }
                        <Typography variant="body1" gutterBottom>
                            Description: {issue.description}
                        </Typography>
                    </Paper>
                </Stack>
                {issue.comments.length > 0 &&
                    <>
                        <Typography variant="h4" gutterBottom>
                            Issue Comments
                        </Typography>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Paper sx={{ width: '100%' }} elevation={3}>
                                <List
                                    sx={{ maxHeight: 300, overflow: 'auto' }}
                                >

                                    {issue.comments.map((comment, index) => (
                                        <ListItem
                                            key={index}>
                                            <ListItemText primary={comment.created_by} secondary={comment.comment} />
                                            <ListItemText sx={{ textAlign: 'right' }} primary={formattedDate(comment.date).split(' ')[0]} secondary={formattedDate(comment.date).split(' ')[1]} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </Stack>
                    </>
                }
                {issue.images.length > 0 &&
                    <>
                        <Typography variant="h4" gutterBottom>
                            Issue Images
                        </Typography>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Paper sx={{ width: '100%' }} elevation={3}>
                                <ImageList cols={3} rowHeight={160}>
                                    {issue.images.map((image, index) => (
                                        <img
                                            key={index}
                                            src={`${apiURL}/images${image}`}
                                            alt={index}
                                        />
                                    ))}
                                </ImageList>
                            </Paper>
                        </Stack>
                    </>
                }
            </Container >
        </>
    );
}