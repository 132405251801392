import { createSlice } from "@reduxjs/toolkit"
import { addRoster, deleteRoster, getRoster, publishRoster, updateRoster } from "./api"

export const rosterSlice = createSlice({
    name: 'roster',
    initialState: {
        roster: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRoster.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(getRoster.fulfilled, (state, action) => {
                state.isLoading = false
                state.roster = action.payload
            })
            .addCase(getRoster.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(addRoster.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(addRoster.fulfilled, (state, action) => {
                state.isLoading = false
                state.roster.push(action.payload)
            })
            .addCase(addRoster.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(deleteRoster.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(deleteRoster.fulfilled, (state, action) => {
                state.isLoading = false
                state.roster = state.roster.filter((item) => item.id !== action.payload)
            })
            .addCase(deleteRoster.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(publishRoster.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(publishRoster.fulfilled, (state, action) => {
                state.isLoading = false
                // update all roster with the action.payload
                state.roster = action.payload
            })
            .addCase(publishRoster.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(updateRoster.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(updateRoster.fulfilled, (state, action) => {
                state.isLoading = false
                const rosterIndex = state.roster.findIndex((item) => item.id === action.payload.id)
                state.roster[rosterIndex] = action.payload
            })
            .addCase(updateRoster.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    }
})

export default rosterSlice.reducer