import { Add } from '@mui/icons-material';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAnnouncements } from '../../redux/features/api';

const AnnList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { announcements, isLoading } = useSelector(state => state.announcements);
    const handleAddNew = () => {
        navigate('/dashboard/announcements/new');
    };

    useEffect(() => {
        dispatch(getAnnouncements());
    }, [dispatch]);
    const columns = [
        {
            flex: 0.3,
            // width: 300,
            field: 'title',
            headerName: 'Title',
            renderCell: ({ row }) => (
                <Stack direction="row">
                    <Box style={{ whiteSpace: 'pre-wrap' }}>
                        <Typography variant="subtitle2">{row.title}</Typography>
                    </Box>
                </Stack>
            ),
            headerClassName: 'super-app-theme--header',
        },
        {
            flex: 0.2,
            // minWidth: 200,
            field: 'branch',
            headerName: 'Properties',
            renderCell: ({ row }) => {
                // convert string to array
                const branch = row.branch.replace(/[[\]']+/g, '');
                const branches = branch.split(',');
                return (
                    <Stack direction="column">
                        {branches.map((item, index) => (
                            <Typography key={index} variant="subtitle2">{item}</Typography>
                        ))}
                    </Stack>
                )
            },
            headerClassName: 'super-app-theme--header',
        },
        {
            flex: 0.2,
            // minWidth: 200,
            field: 'position',
            headerName: 'Position',
            renderCell: ({ row }) => {
                // convert string to array
                const position = row.position.replace(/[[\]']+/g, '');
                const positions = position.split(',');
                return (
                    <Stack direction="column">
                        {positions.map((item, index) => (
                            <Typography textTransform={'capitalize'} key={index} variant="subtitle2">{item}</Typography>
                        ))}
                    </Stack>
                )
            },
            headerClassName: 'super-app-theme--header',
        },
        {
            flex: 0.2,
            // minWidth: 200,
            field: 'date',
            headerName: 'Created Date',
            renderCell: ({ row }) => {
                const dateObj = new Date(row.date);
                // dd/mm/yyyy HH:mm format
                // const formattedDate = `${dateObj.getDate()}/${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj.getFullYear()} ${dateObj.getHours().toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}`;
                const day = dateObj.getDate().toString().padStart(2, '0');
                const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
                const year = dateObj.getFullYear();
                const hours = dateObj.getHours().toString().padStart(2, '0');
                const minutes = dateObj.getMinutes().toString().padStart(2, '0');

                const dateStyle = {
                    // fontWeight: 'bold',
                };

                const timeStyle = {
                    color: 'gray',
                };

                return (
                    <Stack direction="column" spacing={0.1}>
                        <Typography variant="subtitle2" style={dateStyle}>
                            {`${day}-${month}-${year}`}
                        </Typography>
                        <Typography variant="caption" style={timeStyle}>
                            {`${hours}:${minutes}`}
                        </Typography>
                    </Stack>
                );
            },
            headerClassName: 'super-app-theme--header',
        },
    ]
    // table section
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })
    const handleRowClick = (param) => {
        navigate(`/dashboard/announcements/${param.row.id}`);
    }
    return (
        <>
            <Helmet>
                <title>Announcement List</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Announcement List
                    </Typography>
                    <Button onClick={handleAddNew} variant="contained" startIcon={<Add />}>
                        Add New Announcement
                    </Button>
                </Stack>
                <Box sx={{
                        height: 300,
                        width: '100%',
                        '& .super-app-theme--header': {
                            backgroundColor: 'lightgray',
                        },
                    }}>
                    <DataGrid
                        autoHeight
                        // rowHeight={50}
                        getRowHeight={() => 'auto'}
                        loading={isLoading}
                        rows={announcements}
                        columns={columns}
                        pageSizeOptions={[10, 25, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        // sortModel={sortModel}
                        disableRowSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnMenu
                        onRowClick={handleRowClick}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                // showColumnSelector: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}

                    />
                </Box>
            </Container>
        </>
    )
}

export default AnnList