import { useState } from 'react';
import { Stack, IconButton, InputAdornment, TextField, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { authApi } from '../../../redux/features/api';
import Iconify from '../../../components/iconify';


const validationSchema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required').min(5, 'Password is too short - should be 5 chars minimum.'),
});

export default function LoginForm() {
  const loading = useSelector((state) => state.auth.isLoading);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = (data) => {
    dispatch(authApi(data))
      .then((res) => {
        if (res.payload.userData) {
          localStorage.setItem('userData', JSON.stringify(res.payload.userData));
          localStorage.setItem('jwt', JSON.stringify(res.payload.jwt));
          // navigate('/dashboard', { replace: true });
          window.location.href = '/dashboard'
        }
      })
      .catch(() => {
        localStorage.clear();
      });
  };

  const handleClick = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <TextField
            name="username"
            label="Username"
            {...register('username')}
            error={Boolean(errors.username)}
            helperText={errors.username?.message}
          />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            {...register('password')}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Divider sx={{ my: 3 }} />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleClick}
          loading={loading}
        >
          Login
        </LoadingButton>

      </form>
    </>
  );
}
