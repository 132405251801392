import { Container, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { getTraining } from '../../redux/features/api';
import { apiURL } from '../../redux/config';

const TrainingDetail = () => {
    const dispatch = useDispatch();
    // get id from url
    const params = useParams();
    const { id } = params;
    const { training } = useSelector(state => state.training)
    const [trainingDetail, setTrainingDetail] = useState({})
    useEffect(() => {
        dispatch(getTraining())
    }, [dispatch])
    useEffect(() => {
        if (training) {
            const detail = training.find(item => item.id === id)
            setTrainingDetail(detail)
        }
    }, [training, id])
    return (
        <>
            <Helmet>
                <title>Training Detail</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Training Detail
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Paper sx={{ p: 2, width: '100%' }} elevation={3}>
                        <Typography variant="h6" gutterBottom>
                            Title: {trainingDetail?.title}
                        </Typography>
                        <Typography sx={{
                            textTransform: 'capitalize'
                        }} variant="body1" gutterBottom>
                            Position: {trainingDetail?.position?.replace(/[[\]']+/g, '')}
                        </Typography>
                        <Typography sx={{
                            textTransform: 'capitalize'
                        }} variant="body1" gutterBottom>
                            Properties: {trainingDetail?.branch?.replace(/[[\]']+/g, '')}
                        </Typography>
                    </Paper>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Paper sx={{ p: 2, width: '100%' }} elevation={3}>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <div
                                style={{
                                    height: '750px',
                                }}
                            >
                                <Viewer fileUrl={`${apiURL}/images${trainingDetail?.file}`} />
                            </div>
                        </Worker>
                    </Paper>
                </Stack>
            </Container>
        </>
    )
}

export default TrainingDetail