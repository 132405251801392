import { createSlice } from "@reduxjs/toolkit"
import { addTraining, getTraining } from "./api"

export const trainingSlice = createSlice({
    name: 'training',
    initialState: {
        training: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTraining.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(getTraining.fulfilled, (state, action) => {
                state.isLoading = false
                state.training = action.payload
            })
            .addCase(getTraining.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })

            .addCase(addTraining.pending, state => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addTraining.fulfilled, (state, action) => {
                state.isLoading = false;
                state.training.push(action.payload);
            })
            .addCase(addTraining.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    }
})

export default trainingSlice.reducer