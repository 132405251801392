import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { Add } from '@mui/icons-material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import Swal from 'sweetalert2'
import { addProduct, getProducts } from '../../redux/features/api'

const Products = () => {
    const { products, isLoading } = useSelector(state => state.products)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getProducts())
    }, [dispatch])



    // modal section
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 44,
        borderRadius: 2,
        p: 4,
    };

    // add new product
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)
    const validationSchema = yup.object().shape({
        name: yup.string().required('Product Name is required'),
        price: yup.number().nullable().typeError('Product Price must be a number').required('Product Price is required'),
    });
    const { handleSubmit, formState: { errors }, control } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: '',
            price: '',
        },
    })
    const handleAddNew = () => {
        if (selectGroup) {
            handleOpen()
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a Property first!',
            })
        }
    }
    const onSubmit = (data) => {
        // add group to data
        data.branch = selectGroup
        dispatch(addProduct(data))
        .then((res) => {
            if(!res.error) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Product added successfully!',
                })
                handleClose()
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.error.message,
                })
            }
        })
    }


    // select group
    const userData = JSON.parse(localStorage.getItem('userData'));
    const { groups } = userData;
    const [selectGroup, setSelectGroup] = useState('')
    const [filteredProducts, setFilteredProducts] = useState([])
    const selectProperty = (e) => {
        setSelectGroup(e.target.value)
        const filtered = products.filter((item) => item.branch === e.target.value)
        setFilteredProducts(filtered);
    }
    useEffect(() => {
        const filtered = products.filter((item) => item.branch === selectGroup ?? '')
        setFilteredProducts(filtered);
    }, [products, selectGroup])

    // datagrid config
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })
    const columns = [
        {
            flex: 0.3,
            // width: 300,
            field: 'name',
            headerName: 'Product Name',
            renderCell: ({ row }) => (
                <Stack direction="row">
                    <Typography variant="subtitle2">{row.name}</Typography>
                </Stack>
            ),
            headerClassName: 'super-app-theme--header',
        },
        {
            flex: 0.2,
            // minWidth: 200,
            field: 'quantity',
            headerName: 'Quantity',
            renderCell: ({ row }) => (
                <Stack direction="row">
                    <Typography variant="subtitle2" textTransform={'capitalize'}>
                        {row.current_stock}
                    </Typography>
                </Stack>
            ),
            headerClassName: 'super-app-theme--header',
            align: 'right',
            headerAlign: 'right',
        },
        {
            flex: 0.2,
            // minWidth: 200,
            field: 'price',
            headerName: 'Price',
            renderCell: ({ row }) => (
                row.price
            ),
            headerClassName: 'super-app-theme--header',
            align: 'right',
            headerAlign: 'right',
        },

    ]
    return (
        <>
            <Helmet>
                <title>Products</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Product List
                    </Typography>
                    <Button onClick={handleAddNew} variant="contained" startIcon={<Add />}>
                        Add New Product
                    </Button>
                </Stack>
                <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="issueType-label">Property</InputLabel>
                    <Select
                        labelId="issueType-label"
                        id="issueType"
                        label="Issue Type"
                        value={selectGroup}
                        onChange={(e) => selectProperty(e)}
                    >
                        <MenuItem value="" selected disabled ><em>Select</em></MenuItem>
                        {
                            groups.map((group, i) => (
                                <MenuItem key={i} value={group.id}>{group.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <Box
                    sx={{
                        height: 300,
                        width: '100%',
                        '& .super-app-theme--header': {
                            backgroundColor: 'lightgray',
                        },
                    }}
                >
                    <DataGrid
                        autoHeight
                        rowHeight={50}
                        loading={isLoading}
                        rows={filteredProducts ?? []}
                        columns={columns}
                        pageSizeOptions={[10, 25, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        disableRowSelectionOnClick
                        disableColumnSelector
                        disableDensitySelector
                        // add quick search
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                    />
                </Box>
            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                        Add New Product
                    </Typography>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        label="Product Name"
                                        error={Boolean(errors.name)}
                                        helperText={errors.name?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Stack>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <Controller
                                name="price"
                                control={control}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        label="Product Price"
                                        error={Boolean(errors.price)}
                                        helperText={errors.price?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                        <Button variant="contained" onClick={handleClose} color='error' >Cancel</Button>
                        <Button variant="contained" onClick={handleSubmit(onSubmit)}>Add</Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default Products