/* eslint-disable react/prop-types */
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Helmet } from 'react-helmet-async'
import { Box, Button, Container, Divider, Modal, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Circle } from '@mui/icons-material'
import multiMonthPlugin from '@fullcalendar/multimonth'
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { getHolidays, updateHoliday } from '../redux/features/api'


export default function Holidays() {
    const dispatch = useDispatch()
    const holidays = useSelector((state) => state.holidays.holidays)
    const [open, setOpen] = useState(false);
    const [holiday, setHoliday] = useState(null);

    // superuser
    const userData = localStorage.getItem('userData');
    // const isSuperuser = JSON.parse(userData)?.is_superuser ?? false;
    const isStaff = JSON.parse(userData)?.is_staff ?? false;
    useEffect(() => {
        dispatch(getHolidays())
    }, [dispatch])
    const EventRender = ({ event }) => {
        const properties = event.extendedProps.properties.map((property) => property).join(', ');
        return (
            <div style={{ margin: 2 }}>
                <span ><strong>{event.title} </strong>({properties})</span>
            </div>
        )
    }
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 44,
        borderRadius: 2,
        p: 4,
    };
    const approveHoliday = () => {
        dispatch(updateHoliday({ id: holiday.id, approved: true }))
        handleClose();
    }
    const rejectHoliday = () => {
        dispatch(updateHoliday({ id: holiday.id, approved: false }))
        handleClose();
    }
    const FooterInfo = ({ title, color }) => (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <Circle sx={{ color }} />
            <Typography variant="subtitle2">
                {title}
            </Typography>
        </Stack>
    )

    const exportToExcel = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(holidays.map((holiday) => ({
            'Staff': holiday.title,
            'Property': holiday.extendedProps.properties.map((property) => property).join(', '),
            'Status': holiday.extendedProps.approved ? 'Approved' : 'Pending',
            'Start Date': holiday.start_date,
            'End Date': holiday.end_date,
            'Created Date': holiday.extendedProps.created_date,
            'Reason': holiday.reason,
        })));
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `holidays${fileExtension}`);
    }
    return (
        <>
            <Helmet>
                <title>Holidays</title>
            </Helmet>
            <Container>
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Holidays
                    </Typography>
                </Stack> */}
                <FullCalendar
                    plugins={[dayGridPlugin, multiMonthPlugin]}
                    initialView="dayGridMonth" // dayGridWeek
                    weekends
                    events={holidays}
                    eventContent={EventRender}
                    height={800}
                    firstDay={1}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'myCustomButton',
                    }}
                    eventClick={(info) => {
                        // get event id
                        setHoliday(info.event);
                        setOpen(true);
                    }}
                    customButtons={
                        {
                            myCustomButton: {
                                text: 'Print Excel!',
                                click() {
                                    exportToExcel();
                                }
                            }
                        }
                    }
                />
                <Stack p={2} direction="row" alignItems="center" justifyContent="center" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                    <FooterInfo title="Manager / Receptionist" color='#3788d8' />
                    <FooterInfo title="Maintenancer / Night Porter" color='#54D62C' />
                    <FooterInfo title="House Keeper" color='#FFC107' />
                </Stack>
            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Holiday Request
                    </Typography>
                    <Stack>

                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
                            <Typography variant="subtitle">
                                Staff
                            </Typography>
                            <Typography variant="subtitle" textAlign={'left'}>
                                {holiday?.title}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
                            <Typography variant="subtitle">
                                Property
                            </Typography>
                            <Typography variant="subtitle" textAlign={'left'}>
                                {holiday?.extendedProps?.properties.map((property) => property).join(', ')}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
                            <Typography variant="subtitle">
                                Start Date
                            </Typography>
                            <Typography variant="subtitle" textAlign={'left'}>
                                {holiday?.start?.toLocaleDateString()}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
                            <Typography variant="subtitle">
                                End Date
                            </Typography>
                            <Typography variant="subtitle" textAlign={'left'}>
                                {/* end day -1 day */}
                                {new Date(holiday?.end?.getTime() - 86400000).toLocaleDateString()}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
                            <Typography variant="subtitle">
                                Created Date
                            </Typography>
                            <Typography variant="subtitle">
                                {holiday?.extendedProps?.created_date}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
                            <Typography variant="subtitle">
                                Status
                            </Typography>
                            <Typography variant="subtitle">
                                {holiday?.extendedProps?.approved ? 'Approved' : 'Pending'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
                            <Typography variant="subtitle">
                                Reason
                            </Typography>
                            <Typography variant="subtitle">
                                {holiday?.extendedProps?.reason}
                            </Typography>
                        </Stack>
                    </Stack>
                    {(isStaff &&
                        !holiday?.extendedProps?.approved) &&
                        <Stack mt={2} direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                            <Button variant="contained" color="error" onClick={rejectHoliday}>
                                Reject
                            </Button>
                            <Button variant="contained" color="success" onClick={approveHoliday}>
                                Approve
                            </Button>
                        </Stack>
                    }
                </Box>
            </Modal>
        </>
    )
}
