import { createSlice } from "@reduxjs/toolkit"
import { addProduct, getProducts } from "./api"

export const productsSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.isLoading = false
                state.products = action.payload
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(addProduct.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(addProduct.fulfilled, (state, action) => {
                state.isLoading = false
                state.products.push(action.payload)
            })
            .addCase(addProduct.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    }
})

export default productsSlice.reducer