import { Send } from '@mui/icons-material'
import { Button, Checkbox, Container, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useNavigate } from 'react-router-dom'
import { getGroups, sendAnnouncement } from '../../redux/features/api'

const Announcement = () => {
    const dispatch = useDispatch()
    const [description, setDescription] = useState('')
    const [title, setTitle] = useState('')
    const { isLoading } = useSelector((state) => state.announcements)
    const navigate = useNavigate();
    // branch section
    useEffect(() => {
        dispatch(getGroups())
    }, [dispatch])
    const [branchName, setBranchName] = useState(null);
    const [positionName, setPositionName] = useState(null);
    const [onLeave, setOnLeave] = useState(false);
    const groups = useSelector((state) => state.groups.groups)
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        if (value.length === 0) { setBranchName(null) }
        setBranchName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        // setValue('branch', branchName)
    };

    const handleChangePosition = (event) => {
        const {
            target: { value },
        } = event;
        if (value.length === 0) { setPositionName(null) }
        setPositionName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        // setValue('branch', branchName)
    }

    const userRoles = [
        { id: 'housekeeper', name: 'Housekeeper' },
        { id: 'receptionist', name: 'Receptionist' },
        { id: 'maintenancer', name: 'Maintenancer' },
        { id: 'nightporter', name: 'Nightporter' },
        { id: 'manager', name: 'Manager' },
    ]

    const SwalWarning = (desc) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: desc,
        })
    }
    const handleSave = () => {
        const data = {
            title,
            description,
            branch: branchName,
            position: positionName,
            onLeave,
        }
        
        if (data.branch === null) {
            SwalWarning('Please select property!')
        } else if (data.position === null) {
            SwalWarning('Please select position!')
        } else if (data.title === '') {
            SwalWarning('Please fill in title!')
        } else if (data.description === '') {
            SwalWarning('Please fill in description!')
        } else {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                // cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, send it!',
                showLoaderOnConfirm: isLoading,
                preConfirm: () => new Promise(() => {
                    dispatch(sendAnnouncement(data))
                        .then((res) => {
                            const { id } = res.payload;
                            if (!res.error) {
                                Swal.update({
                                    title: 'Success',
                                    text: 'Your announcement has been sent!',
                                    icon: 'success',
                                    allowOutsideClick: true,
                                    allowEscapeKey: true,
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                });
                                navigate(`/dashboard/announcements/${id}`)
                            } else {
                                Swal.showValidationMessage(res.error.message); // Hata mesajı göster
                            }
                        })
                }),
            });
        }
    }

    return (
        <>
            <Helmet>
                <title>Announcement New</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Announcement New
                    </Typography>
                </Stack>
                <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>

                    <FormControl sx={{ m: 1, width: "100%" }}>
                        <InputLabel id="demo-multiple-checkbox-label">Property</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            multiple
                            value={branchName || []}
                            onChange={handleChange}
                            input={<OutlinedInput label="Property" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {groups.map((item, i) => (
                                <MenuItem key={i} value={item.name}>
                                    <Checkbox checked={branchName?.indexOf(item.name) > -1} />
                                    <ListItemText primary={item.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: "100%" }}>
                        <InputLabel id="demo-multiple-checkbox-label">Position</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            multiple
                            value={positionName || []}
                            onChange={handleChangePosition}
                            input={<OutlinedInput label="Position" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {userRoles.sort((a, b) => a.id.localeCompare(b.id)).map((item, i) => (
                                <MenuItem key={i} value={item.name}>
                                    <Checkbox checked={positionName?.indexOf(item.name) > -1} />
                                    <ListItemText primary={item.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <TextField
                        id="outlined-multiline-flexible"
                        label="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        sx={{ width: '100%' }}
                    />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1}>
                    <div id="editor" style={{ width: '100%' }}>
                        <CKEditor
                            editor={ClassicEditor}
                            data=""
                            onChange={(event, editor) => {
                                // console.log('editor', editor.getData());
                                const data = editor.getData();
                                setDescription(data)
                            }}
                            config={{
                                toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', 'undo', 'redo'],
                            }}

                        />
                    </div>
                </Stack>
                <Stack direction="row" justifyContent="flex-end" mt={1}>
                    <FormControlLabel
                        label="Include Users On Holiday"
                        control={
                            <Checkbox
                                checked={onLeave}
                                onChange={(e) => setOnLeave(e.target.checked)}
                                inputProps={{ 'aria-label': 'Parent' }}
                            />
                        } />
                    <Button onClick={handleSave} variant="contained" startIcon={<Send />}>
                        Send Announcement
                    </Button>
                </Stack>
            </Container>
        </>
    )
}

export default Announcement