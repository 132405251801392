import { Add } from '@mui/icons-material'
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from 'sweetalert2'
import { addUser, getGroups, getUsers } from '../../redux/features/api'

const Users = () => {
    const dispatch = useDispatch()
    const [branchName, setBranchName] = useState(null);
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        if (value.length === 0) { setBranchName(null) }
        setBranchName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        // setValue('branch', branchName)
    };

    const { users, isLoading } = useSelector((state) => state.users)

    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })
    useEffect(() => {
        dispatch(getUsers())
        dispatch(getGroups())
    }, [dispatch])

    // const get groups
    const groups = useSelector((state) => state.groups.groups)
    const UserRoles = [
        { value: 'housekeeper', name: 'Housekeeper' },
        { value: 'receptionist', name: 'Receptionist' },
        { value: 'maintenancer', name: 'Maintenancer' },
        { value: 'nightporter', name: 'Nightporter' },
        { value: 'manager', name: 'Manager' },
    ]

    // modal section
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 44,
        borderRadius: 2,
        p: 4,
    };
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const validationSchema = yup.object().shape({
        first_name: yup.string().required('First Name is required'),
        last_name: yup.string().required('Last Name is required'),
        email: yup.string().required('Email is required').email('Email is invalid'),
        role: yup.string().required('Position is required'),
        groups: yup.array().required('Property is required'),
        roster_creater: yup.boolean().required('Roster Creater is required'),
        // check branch is null
        // branch: yup.array().test('array', 'Property is required', (value) => value !== null),
    });
    const { handleSubmit, formState: { errors }, control, reset, setValue } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            first_name: '',
            last_name: '',
            email: '',
            role: '',
            groups: null,
            roster_creater: false,
        },
    });
    useEffect(() => {
        setValue('groups', branchName)
    }, [branchName, setValue])
    const onSubmit = (data) => {
        dispatch(addUser(data))
            .then((res) => {
                if (!res.error) {
                    handleClose();
                    // dispatch(getUsers())
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res.error.message,
                    })
                }
            })
    }

    const columns = [
        {
            flex: 0.3,
            // width: 300,
            field: 'fullName',
            headerName: 'Full Name',
            renderCell: ({ row }) => (
                <Stack direction="row">
                    <Typography variant="subtitle2">{`${row.first_name} ${row.last_name}`}</Typography>
                </Stack>
            ),
            headerClassName: 'super-app-theme--header',
        },
        {
            flex: 0.2,
            // minWidth: 200,
            field: 'email',
            headerName: 'Email',
            renderCell: ({ row }) => (
                row.email
            ),
            headerClassName: 'super-app-theme--header',
        },
        {
            flex: 0.2,
            // minWidth: 200,
            field: 'role',
            headerName: 'Position',
            renderCell: ({ row }) => (
                <Stack direction="row">
                    <Typography variant="subtitle2" textTransform={'capitalize'}>
                        {row.userprofile?.role}
                    </Typography>
                </Stack>
            ),
            headerClassName: 'super-app-theme--header',
        },

    ]
    const handleAddNew = () => {
        reset();
        setBranchName(null);
        setOpen(true);
    }
    return (
        <>
            <Helmet>
                <title>Users</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Staff List
                    </Typography>
                    <Button onClick={handleAddNew} variant="contained" startIcon={<Add />}>
                        Add New User
                    </Button>
                </Stack>
                <Box
                    sx={{
                        height: 300,
                        width: '100%',
                        '& .super-app-theme--header': {
                            backgroundColor: 'lightgray',
                        },
                    }}
                >
                    <DataGrid
                        autoHeight
                        rowHeight={50}
                        loading={isLoading}
                        rows={users}
                        columns={columns}
                        pageSizeOptions={[10, 25, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        disableRowSelectionOnClick
                        disableColumnSelector
                        disableDensitySelector
                    />
                </Box>
            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                    zIndex: 1,
                }}
            >
                <Box sx={style}>
                    <Typography mb={1} id="modal-modal-title" variant="h6" component="h2">
                        Add New Staff
                    </Typography>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <Controller
                                name="first_name"
                                control={control}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        label="First Name"
                                        error={Boolean(errors.first_name)}
                                        helperText={errors.first_name?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Stack>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <Controller
                                name="last_name"
                                control={control}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        label="Last Name"
                                        error={Boolean(errors.last_name)}
                                        helperText={errors.last_name?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Stack>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        label="Email"
                                        error={Boolean(errors.email)}
                                        helperText={errors.email?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Stack>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>

                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-multiple-checkbox-label">Property</InputLabel>
                            <Controller
                                name="groups"
                                control={control}
                                render={() => (
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        // id="branch"
                                        multiple
                                        value={branchName || []}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Property" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                        error={Boolean(errors.groups)}
                                    >
                                        {groups.map((item, i) => (
                                            <MenuItem key={i} value={item.name}>
                                                <Checkbox checked={branchName?.indexOf(item.name) > -1} />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            <FormHelperText error={Boolean(errors?.groups)}>{errors.groups?.message}</FormHelperText>
                        </FormControl>
                    </Stack>

                    <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="user-label">Position</InputLabel>
                            <Controller
                                name="role"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        labelId="role-label"
                                        id="role"
                                        label="Position"
                                        {...field}
                                        error={Boolean(errors.role)}
                                    >
                                        {UserRoles.map((item, i) => (
                                            <MenuItem key={i} value={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            <FormHelperText error={Boolean(errors.role)}>{errors.role?.message}</FormHelperText>
                        </FormControl>
                    </Stack>
                        <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                {/* <InputLabel id="user-label">Position</InputLabel> */}
                                <Controller
                                    name="roster_creater"
                                    control={control}
                                    render={({ field }) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label="Roster Creater"
                                                id='roster_creater'
                                                {...field}
                                            />
                                        </FormGroup>
                                    )}
                                />
                                <FormHelperText error={Boolean(errors.roster_creater)}>{errors.roster_creater?.message}</FormHelperText>
                            </FormControl>
                        </Stack>
                    <Stack mt={2} direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>

                        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                            Submit
                        </Button>
                    </Stack>
                </Box>
            </Modal>
            {/* {stateError && swalComponent()} */}

        </>
    )
}

export default Users

// import * as React from 'react';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import ListItemText from '@mui/material/ListItemText';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


// const names = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

// export default function MultipleSelectCheckmarks() {
//   const [personName, setPersonName] = React.useState<string[]>([]);

//   const handleChange = (event: SelectChangeEvent<typeof personName>) => {
//     const {
//       target: { value },
//     } = event;
//     setPersonName(
//       // On autofill we get a stringified value.
//       typeof value === 'string' ? value.split(',') : value,
//     );
//   };

//   return (
//     <div>
//       <FormControl sx={{ m: 1, width: 300 }}>
//         <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
//         <Select
//           labelId="demo-multiple-checkbox-label"
//           id="demo-multiple-checkbox"
//           multiple
//           value={personName}
//           onChange={handleChange}
//           input={<OutlinedInput label="Tag" />}
//           renderValue={(selected) => selected.join(', ')}
//           MenuProps={MenuProps}
//         >
//           {names.map((name) => (
//             <MenuItem key={name} value={name}>
//               <Checkbox checked={personName.indexOf(name) > -1} />
//               <ListItemText primary={name} />
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </div>
//   );
// }
