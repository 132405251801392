import { createSlice } from "@reduxjs/toolkit"
import { getGroups } from "./api"

export const groupsSlice = createSlice({
    name: 'groups',
    initialState: {
        groups: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGroups.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(getGroups.fulfilled, (state, action) => {
                state.isLoading = false
                state.groups = action.payload
            })
            .addCase(getGroups.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    }
})

export default groupsSlice.reducer