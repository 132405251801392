import { createSlice } from "@reduxjs/toolkit"
import { updateHoliday, getHolidays } from "./api"

export const holidaysSlice = createSlice({
    name: 'holidays',
    initialState: {
        holidays: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHolidays.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(getHolidays.fulfilled, (state, action) => {
                state.isLoading = false
                state.holidays = action.payload
            })
            .addCase(getHolidays.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(updateHoliday.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(updateHoliday.fulfilled, (state, action) => {
                state.isLoading = false
                const updatedHoliday = action.payload;

                // Tatil nesnesinin onay durumu true ise, güncellemeyi yap
                if (updatedHoliday.approved) {
                    const index = state.holidays.findIndex((holiday) => holiday.id === updatedHoliday.id);
                    state.holidays[index] = updatedHoliday;
                } else {
                    // Onay durumu false ise, tatil nesnesini listeden kaldır
                    state.holidays = state.holidays.filter((holiday) => holiday.id !== updatedHoliday.id);
                }
            })
            .addCase(updateHoliday.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    }
})

export default holidaysSlice.reducer