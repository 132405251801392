/* eslint-disable react/no-danger */
/* eslint-disable arrow-body-style */
import { Container, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAnnouncements } from '../../redux/features/api';

const AnnDetail = () => {
    const { id } = useParams();
    const { announcements } = useSelector(state => state.announcements);
    const announcement = announcements.find(item => item.id === parseInt(id, 10));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAnnouncements());
    }, [dispatch]);
    return (
        <>
            <Helmet>
                <title>Announcement Detail</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Announcement Detail
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h6" gutterBottom>
                        Title: {announcement?.title}
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h6" gutterBottom>
                        Properties: {announcement?.branch.replace(/[[\]']+/g, '')}
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h6" gutterBottom>
                        Positions: {announcement?.position.replace(/[[\]']+/g, '')}
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h6" gutterBottom>
                        Description
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography border={1} width={'100%'} p={2}>
                        <span dangerouslySetInnerHTML={{ __html: announcement?.description }} />
                    </Typography>
                </Stack>
            </Container>

        </>
    )
}

export default AnnDetail