import { Autocomplete, Button, Container, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Add, Save } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { addProductsCounting, getProducts } from '../../redux/features/api';

const CountingNew = () => {
    const { state: { group } } = useLocation();
    const dispatch = useDispatch()
    const [product, setProduct] = useState(null);
    const [description, setDescription] = useState('')
    const [total, setTotal] = useState(0)
    const { products } = useSelector((state) => state.products)
    const navigate = useNavigate();
    // product list
    const [productList, setProductList] = useState([])
    const [quantity, setQuantity] = useState()
    useEffect(() => {
        dispatch(getProducts())
    }, [dispatch])
    const handleProductChange = (event, newValue) => {
        if (newValue) {
            setProduct(newValue)
            quantityRef.current.focus();
        } else {
            setProduct(null)
        }
    }
    const quantityRef = useRef(null);
    const autocompleteRef = useRef(null);

    const handleAddNew = () => {
        // check if product already in list
        const isExist = productList.find((item) => item.id === product.id)
        if (isExist) {
            alert('Product already in list')

        } else {
            const newProduct = {
                ...product,
                quantity
            }
            setProductList([...productList, newProduct])
            setProduct(null)
            setQuantity('')
            quantityRef.current.value = ''
            // unfocus quantity input
            quantityRef.current.blur()
            autocompleteRef.current.focus();
            const newTotal = parseFloat((total + (product.price * quantity)).toFixed(2));
            setTotal(newTotal)
        }
    }
    const branchProducts = products.filter((item) => item.branch === group)

    const handleDelete = (id) => {
        const newList = productList.filter((item) => item.id !== id)
        setProductList(newList)
    }
    const handleSave = () => {
        if (!description) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter description!',
            })
        }
        else if (!productList.length) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please add product!',
            })
        }
        else {
            const items = productList.map((item) => ({
                product: item.id,
                quantity: item.quantity,
                price: item.price
            }))

            const data = {
                total,
                branch: group,
                description,
                items
            }
            dispatch(addProductsCounting(data))
                .then((res) => {
                    console.log('res', res);
                    if (!res.error) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Counting saved!',
                        })
                        const { id } = res.payload
                        navigate(`/dashboard/counting/${id}`)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res.error.message,
                        })
                    }
                })
        }
    }
    return (
        <>
            <Helmet>
                <title>New Counting</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Counting
                    </Typography>
                    <Button onClick={handleSave} variant="contained" startIcon={<Save />}>
                        Save Counting
                    </Button>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <TextField
                        id="outlined-multiline-flexible"
                        label="Description"
                        multiline
                        rows={2}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        sx={{ width: '100%' }}
                    />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2} mt={5}>
                    <Autocomplete
                        disablePortal

                        options={branchProducts}
                        value={product ?? null}
                        getOptionLabel={(option) => option.name}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        sx={{ width: '55%' }}
                        onChange={handleProductChange}
                        renderInput={(params) => <TextField inputRef={autocompleteRef} {...params} label="Product" />}
                    />
                    <TextField
                        value={product?.price ?? ''}
                        label="Price"
                        disabled
                        sx={{ width: '15%' }}
                    />
                    <TextField
                        id="outlined-multiline-flexible"
                        label="Quantity"
                        type='number'
                        sx={{ width: '15%' }}
                        inputRef={quantityRef}
                        value={quantity ?? ''}
                        onChange={(e) => setQuantity(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                if (quantityRef.current.value) {
                                    handleAddNew()
                                }
                            }
                        }}
                        inputProps={{
                            step: 1, 
                            pattern: "[0-9]*",
                            onInput: (e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value, 10) || 0).toString().slice(0, 3);
                            }
                        }}
                    />
                    <Button
                        disabled={!product || !quantity}
                        sx={{ width: '15%' }} onClick={handleAddNew} variant="contained" startIcon={<Add />}>
                        Add
                    </Button>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5}>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Product</TableCell>
                                    <TableCell align="right">Quantity</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productList.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.quantity}</TableCell>
                                        <TableCell align="right">{row.price}</TableCell>
                                        <TableCell align="right">{(row.quantity * row.price).toFixed(2)}</TableCell>
                                        <TableCell align="right">
                                            <Button onClick={
                                                () => handleDelete(row.id)
                                            } variant="contained" color="error">Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Stack>
                <Typography variant="h5" gutterBottom textAlign={'right'} mt={5}>
                    Total: {total.toFixed(2)}
                </Typography>
            </Container>
        </>
    )
}

export default CountingNew