/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import FullCalendar from '@fullcalendar/react';
import { Box, Button, Checkbox, Container, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, Icon, InputLabel, MenuItem, Modal, Select, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import datGridPlugin from '@fullcalendar/daygrid';
import { CheckCircle, Circle, DoneAll, DoneAllOutlined, DoneAllRounded, DoneAllSharp, DoneAllTwoTone } from '@mui/icons-material';
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { TimeField } from '@mui/x-date-pickers';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { addRoster, checkDayOffHoliday, deleteRoster, getRoster, getUsers, publishRoster, updateRoster } from '../redux/features/api';

const Roster = () => {
    // create new css style
    const textShadowStyle = {
        textShadow: '1px 1px 1px rgba(0,0,0,0.3)'
    }
    const [selectedDates, setSelectedDates] = useState([]);
    // const [checkedDates, setCheckedDates] = useState({});
    const handleClose = () => {
        setSelectedTimes({});
        setSelectedDates([]);
        setOpen(false);
    }

    const SwalWarning = (desc) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: desc,
        })
    }
    const dispatch = useDispatch()

    const calRef = useRef(null);
    // modal section
    const [open, setOpen] = useState(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: open ? 600 : 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 44,
        borderRadius: 2,
        p: 4,
    };
    const users = useSelector((state) => state.users.users)

    const validationSchema = yup.object().shape({
        user: yup.string().required('User is required'),
    });
    const { handleSubmit, formState: { errors }, control, reset, getValues } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            user: '',
        },
    });

    // edit modal section
    const [editOpen, setEditOpen] = useState(false);
    const handleEditClose = () => setEditOpen(false);
    const [editStartTime, setEditStartTime] = useState('');
    const [editEndTime, setEditEndTime] = useState('');
    const [event, setEvent] = useState({});

    // roster section
    const { roster, isLoading } = useSelector((state) => state.roster)
    const userData = JSON.parse(localStorage.getItem('userData'));
    const { groups } = userData;
    const [selectGroup, setSelectGroup] = useState('')
    const [filteredRoster, setFilteredRoster] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const [selectedDate, setSelectedDate] = useState('');
    const selectProperty = (e) => {
        setSelectGroup(e.target.value)
        const filtered = roster.filter((item) => item.branch === e.target.value)
        // filter users by users.groups name
        const filterUsers = users.filter((item) => {
            const userGroups = item.groups.map((group) => group.id)
            return userGroups.includes(e.target.value)
        })
        setFilteredUsers(filterUsers);
        setFilteredRoster(filtered);
    }
    useEffect(() => {
        dispatch(getRoster())
        dispatch(getUsers())
    }, [dispatch])
    useEffect(() => {
        if (selectGroup) {
            const filtered = roster.filter((item) => item.branch === selectGroup)
            setFilteredRoster(filtered);
        }
    }, [roster, selectGroup])
    const FooterInfo = ({ title, color }) => (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <Circle sx={{ color }} />
            <Typography variant="subtitle2">
                {title}
            </Typography>
        </Stack>
    )
    const EventRender = ({ event }) => (
        // <div style={{
        //     padding: '5px',
        // }}>
        //     <span style={{
        //         whiteSpace: 'initial',
        //     }}>{event.title}</span>
        //     <br />
        //     <span>{event.extendedProps.startTime} - {event.extendedProps.endTime}</span>
        //     <span style={{
        //         float: 'right',
        //         whiteSpace: 'initial',
        //     }}>{event.extendedProps.role.name}</span>
        // </div>
        <Box p={1}>
            <Typography style={textShadowStyle} sx={{
                whiteSpace: 'initial',
                fontWeight: 'bold',
            }} color={'white'}>
                {event.title}
            </Typography>
            <Divider sx={{
                borderColor: 'white',
            }} />
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                <Typography style={textShadowStyle} color={'white'} fontSize={'lg'}>
                    {event.extendedProps.startTime} - {event.extendedProps.endTime}
                </Typography>
                {event.extendedProps.seen && (
                    <CheckCircle fontSize='small' />
                )}
            </Stack>
        </Box>
    )
    const handleDateClick = (arg) => {
        // console.log('arg', arg);
        const todayOnlyDate = new Date().toISOString().slice(0, 10);
        if (arg.dateStr < todayOnlyDate) {
            // alert('Geçmiş tarihli etkinlik oluşturamazsınız!');
            return;
        }
        if (!selectGroup) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a property!',
            })
            return;
        }
        const calendar = calRef.current.getApi();
        const start = calendar.view.activeStart;
        const end = calendar.view.activeEnd;
        const dateArray = [];

        while (start < end) {
            const date = start.toLocaleDateString('en-GB')
            dateArray.push(date);
            start.setDate(start.getDate() + 1);
        }
        setSelectedDates(dateArray);

        setSelectedDate(arg.dateStr);
        reset();
        setOpen(true);
    };
    const handleEventClick = (arg) => {
        setEvent(arg.event);
        setEditOpen(true);
    }

    const handleUpdate = async () => {

        if (!editStartTime) { SwalWarning('Please fill in start time!'); return; }
        if (!editEndTime) { SwalWarning('Please fill in end time!'); return; }

        // get only time from date
        const onlyTimeStart = new Date(editStartTime).toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric' })
        const onlyTimeEnd = new Date(editEndTime).toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric' })

        const rosterInfo = {
            start_time: onlyTimeStart,
            end_time: onlyTimeEnd,
        }
        await dispatch(updateRoster({ id: event.id, ...rosterInfo }))
            .then((res) => {
                if (!res.error) {
                    handleEditClose();
                } else {
                    SwalWarning(res.error.message);
                }
            })
    }

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            // cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showLoaderOnConfirm: isLoading,
            preConfirm: () => new Promise(() => {
                dispatch(deleteRoster(event.id))
                    .then((res) => {
                        if (!res.error) {
                            Swal.update({
                                title: 'Deleted!',
                                text: 'Your roster has been deleted.',
                                icon: 'success',
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                            });
                        } else {
                            Swal.showValidationMessage(res.error.message); // Hata mesajı göster
                        }
                    })
            }),
        });
    };

    const [selectedTimes, setSelectedTimes] = useState({});

    const handleCheckboxChange = (date) => {
        setSelectedTimes(prevState => ({
            ...prevState,
            [date]: prevState[date] ? null : { startTime: '', endTime: '' } // Set initial time values
        }));
    };

    const handleTimeChange = (date, field, value) => {
        setSelectedTimes(prevState => ({
            ...prevState,
            [date]: {
                ...prevState[date],
                [field]: value
            }
        }));
    };

    const onSubmit = async (data) => {
        const customErrors = [];
        const selectedData = Object.entries(selectedTimes).reduce((acc, [date, times]) => {
            if (times && times.startTime && times.endTime) {
                acc.push({
                    date,
                    startTime: times.startTime,
                    endTime: times.endTime
                });
            } else if (times) {
                customErrors.push(`Please fill the ${date} date's time fields`);
            }
            return acc;
        }, []);
        if (customErrors.length) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: customErrors.join('\n'),
            })
            // make array to empty
            customErrors.length = 0;
            return;
        }
        if (selectedData.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a date!',
            })
            return;
        }
        selectedData.forEach((item) => {
            const parts = item.date.split("/");

            const dateObject = `${parts[2]}-${parts[1]}-${parts[0]}`;
            const onlyTimeStart = new Date(item.startTime).toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric' })
            const onlyTimeEnd = new Date(item.endTime).toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric' })
            const formData = {
                start_time: onlyTimeStart,
                end_time: onlyTimeEnd,
                date: dateObject,
                branch: selectGroup,
                user: data.user
            }
            // console.log('formData', formData);
            dispatch(addRoster(formData))
                .then(() => {
                    handleClose();
                    setSelectedTimes({});
                    setSelectedDates([]);
                })
        })

        // const onlyTimeStart = new Date(data.start_time).toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric' })
        // const onlyTimeEnd = new Date(data.end_time).toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric' })
        // const formData = {
        //     start_time: onlyTimeStart,
        //     end_time: onlyTimeEnd,
        //     date: selectedDate,
        //     branch: selectGroup,
        //     user: data.user
        // }

        // await dispatch(addRoster(formData))
        //     .then(() => {
        //         handleClose();
        //     })

    };

    const exportExcel = () => {
        if (selectGroup === 5) {
            SwalWarning('Not at the moment for Nightporter!');
            return;
        }
        const calendar = calRef.current.getApi();
        const start = calendar.view.activeStart;
        const end = calendar.view.activeEnd;

        const stuffList = [];
        const allDates = [];

        const currentDate = new Date(start);
        // start ve end arasındaki tüm tarihleri al
        while (currentDate < end) {
            allDates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        const publishRoster = filteredRoster.filter((item) => item.published === true);
        publishRoster.forEach(x => {
            const rosterDate = new Date(x.date);
            if (rosterDate >= start && rosterDate <= end) {
                const userId = x.user;
                const existingIndex = stuffList.findIndex(item => item.id === userId);
                if (existingIndex === -1) {
                    stuffList.push({
                        id: userId,
                        name: x.title,
                        position: x.extendedProps.role.name,
                        dates: allDates
                    });
                }
            }
        });

        stuffList.forEach((item) => {
            item.dates = item.dates.map((date) => {
                const gmtZeroDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
                const record = publishRoster.filter((x) => x.date === gmtZeroDate.toISOString().slice(0, 10) && x.user === item.id);
                const totalHours = record.reduce((acc, item) => acc + calculateTotalHours(item.start_time, item.end_time), 0)
                return {
                    date: gmtZeroDate.toISOString().slice(0, 10),
                    totalHours,
                };
            });
        });


        // console.log('allDates', allDates);
        if (stuffList.length === 0) { SwalWarning('Please add staff to roster!'); return; }

        // // excel file section
        const titlePromise = calendar.view.title;
        const groupName = groups.find((item) => item.id === selectGroup).name;
        const title = `${`${groupName} | ${titlePromise}`}`;

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        // Yeni veri yapısını oluştur
        const newDataList = [];

        // Her personel için çalışma saatlerini sırala
        stuffList.forEach(item => {
            const rowData = {
                'Staff': item.name,
                'Position': item.position,
                'Week Hours': 0,
                'Sunday Hours': 0
            };

            item.dates.forEach(dateItem => {
                const date = new Date(dateItem.date);
                const dayOfWeek = date.getDay();

                rowData[date.toISOString().slice(0, 10)] = dateItem.totalHours;

                if (dayOfWeek !== 0) {
                    // Pazar olmayan günler için çalışma saatlerini topla
                    rowData['Week Hours'] += dateItem.totalHours;
                } else {
                    // Pazar günleri için çalışma saatlerini ayrı bir sütuna kaydet
                    rowData['Sunday Hours'] += dateItem.totalHours;
                }
            });

            newDataList.push(rowData);
        });

        // Excel dosyasını oluştur
        const ws = XLSX.utils.json_to_sheet(newDataList);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${title}${fileExtension}`);


    }

    const calculateTotalHours = (startTime, endTime) => {
        if (startTime && endTime) {
            const startDate = new Date(`1970/01/01 ${startTime}`);
            const endDate = new Date(`1970/01/01 ${endTime}`);

            // Eğer bitiş zamanı, başlangıç zamanından önceyse bir gün ekleyerek düzelt
            if (endDate < startDate) {
                endDate.setDate(endDate.getDate() + 1);
            }

            const hoursDifference = Math.abs(startDate - endDate) / 36e5;
            // 2 ondalık basamağa yuvarla ve döndür
            return Math.round(hoursDifference * 100) / 100;
        }
        return 0;
    }
    const checkHoliday = () => {
        const user = getValues('user');
        const result = [];
        if (!user) { SwalWarning('Please select a user!'); return; }

        // Array of promises
        const promises = selectedDates.map((x) => {
            const parts = x.split("/");
            const date = `${parts[2]}-${parts[1]}-${parts[0]}`;
            return dispatch(checkDayOffHoliday({ user, date })).then((res) => {
                if (res.payload !== "") {

                    result.push({
                        date,
                        result: res
                    });
                }
            });
        });

        // open swal loading
        Swal.fire({
            title: 'Please wait...',
            html: 'Checking day off and holiday...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();

                Promise.all(promises).then(() => {
                    // close swal loading
                    Swal.hideLoading();
                    Swal.update({
                        title: 'Completed!',
                        icon: 'success',
                        html: result.length > 0 ? result.map((x) => `${JSON.stringify(x.result.payload)}`).join('<br />') : 'No day off or holiday',
                    });
                }).catch((error) => {
                    Swal.update({
                        title: 'Error!',
                        icon: 'error',
                        html: `An error occurred: ${error.message}`
                    });
                });
            },
        });
    };


    return (
        <>
            <Helmet>
                <title>Roster</title>
            </Helmet>
            <Container>
                <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="issueType-label">Property</InputLabel>
                    <Select
                        labelId="issueType-label"
                        id="issueType"
                        label="Issue Type"
                        disabled={isLoading}
                        value={selectGroup}
                        onChange={(e) => selectProperty(e)}
                    >
                        <MenuItem value="" selected disabled ><em>Select</em></MenuItem>
                        {
                            groups.map((group, i) => (
                                <MenuItem key={i} value={group.id}>{group.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FullCalendar
                    plugins={[timeGridPlugin, interactionPlugin, datGridPlugin]}
                    initialView="dayGridWeek" // dayGridWeek
                    events={filteredRoster}
                    eventContent={EventRender}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'publish Excel makePDF',
                    }}
                    eventClick={handleEventClick}
                    firstDay={1}
                    dateClick={handleDateClick}
                    eventOrder={(a, b) => {
                        const roleOrderA = a.extendedProps.role.order;
                        const roleOrderB = b.extendedProps.role.order;
                        if (roleOrderA !== roleOrderB) {
                            return roleOrderA - roleOrderB; // Role göre sırala
                        }
                        const startTimeA = a.extendedProps.startTime;
                        const startTimeB = b.extendedProps.startTime;
                        return startTimeA.localeCompare(startTimeB); // Aynı role sahipsen, startTime'a göre sırala

                    }}
                    ref={calRef}
                    key={filteredRoster.length}
                    customButtons={
                        {
                            makePDF: {
                                text: 'Print!',
                                click() {
                                    if (!selectGroup) {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: 'Please select a property!',
                                        })
                                        return;
                                    }

                                    // get the calendar's current view
                                    const calendar = calRef.current.getApi();
                                    // get calendar date range
                                    const start = calendar.view.activeStart;
                                    const end = calendar.view.activeEnd;
                                    const dateArray = [];
                                    const dateForBody = [];
                                    const resultArray = [];
                                    while (start < end) {
                                        const date = start.toLocaleDateString('en-GB')
                                        const nd = new Date(start.toDateString());
                                        dateArray.push(date);
                                        start.setDate(start.getDate() + 1);
                                        dateForBody.push(nd);
                                    }
                                    dateForBody.forEach((date) => {
                                        const day = String(date.getDate()).padStart(2, '0'); // Günü al ve 2 haneli olarak biçimlendir
                                        const month = String(date.getMonth() + 1).padStart(2, '0'); // Ayı al (0-11 aralığında) ve 2 haneli olarak biçimlendir
                                        const year = date.getFullYear(); // Yılı al

                                        const formattedDate = `${year}-${month}-${day}`;
                                        const filtered = filteredRoster.filter((item) => item.date === formattedDate).sort((a, b) => {
                                            const roleOrderA = a.extendedProps.role && a.extendedProps.role.order ? a.extendedProps.role.order : Infinity; // Eğer role veya order değeri yoksa, sıralama en sona yapılacak
                                            const roleOrderB = b.extendedProps.role && b.extendedProps.role.order ? b.extendedProps.role.order : Infinity;

                                            if (roleOrderA !== roleOrderB) {
                                                return roleOrderA - roleOrderB; // Role göre sırala
                                            }
                                            const startTimeA = a.start_time;
                                            const startTimeB = b.start_time;
                                            return startTimeA.localeCompare(startTimeB); // Aynı role sahipsen, startTime'a göre sırala

                                        });
                                        if (filtered.length > 0) {
                                            const result = filtered.map((item) => {
                                                const userInfo = `${item.title}`
                                                const rosterInfo = `${item.start_time.slice(0, 5)} - ${item.end_time.slice(0, 5)}`
                                                return `${userInfo} \n${rosterInfo}\n`
                                            })
                                            resultArray.push([result.join('\n')])
                                        } else {
                                            resultArray.push('No Staff')

                                        }
                                    })
                                    if (resultArray.length === 0) {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: 'Please add staff to roster!',
                                        })
                                        return;
                                    }
                                    // get a promise for the current view's title
                                    const titlePromise = calendar.view.title; // calendar.view.title for pdf title

                                    // create pdf document with range dates event
                                    const doc = new jsPDF(
                                        {
                                            orientation: 'landscape',
                                            format: 'a4',
                                            // unit: 'in',
                                            // format: [4, 2]
                                        }
                                    );
                                    doc.autoTable({
                                        head: [dateArray],
                                        body: [resultArray],
                                        startY: 5,
                                        startX: 5,
                                        margin: { left: 5, top: 5, right: 5, bottom: 5 },
                                        autoSize: false,
                                        columnStyles: {
                                            0: { cellWidth: 41 },
                                            1: { cellWidth: 41 },
                                            2: { cellWidth: 41 },
                                            3: { cellWidth: 41 },
                                            4: { cellWidth: 41 },
                                            5: { cellWidth: 41 },
                                            6: { cellWidth: 41 },
                                            // etc
                                        },
                                    });
                                    const groupName = groups.find((item) => item.id === selectGroup).name;
                                    doc.save(`${`${groupName} | ${titlePromise}`}`);

                                    // createPDF()
                                },
                            },
                            publish: {
                                text: 'Publish',
                                click() {
                                    if (!selectGroup) {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: 'Please select a property!',
                                        })
                                        return;
                                    }
                                    const calendar = calRef.current.getApi();
                                    const start = calendar.view.activeStart;
                                    const end = calendar.view.activeEnd;
                                    const titlePromise = calendar.view.title;
                                    const groupName = groups.find((item) => item.id === selectGroup).name;
                                    const title = `${`${groupName} | ${titlePromise}`}`;


                                    Swal.fire({
                                        title: 'Are you sure?',
                                        text: "You won't be able to revert this!",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Yes, publish it!',
                                        showLoaderOnConfirm: isLoading,
                                        preConfirm: () => new Promise(() => {
                                            // İşlemi yap ve sonucu resolve ile dön
                                            const formData = {
                                                title,
                                                start_date: start.toISOString().slice(0, 10),
                                                end_date: end.toISOString().slice(0, 10),
                                                branch: selectGroup,
                                            };

                                            dispatch(publishRoster(formData))
                                                .then((res) => {
                                                    if (!res.error) {
                                                        // resolve();
                                                        Swal.update({
                                                            title: 'Published!',
                                                            text: 'Your roster has been published.',
                                                            icon: 'success',
                                                            allowOutsideClick: true,
                                                            allowEscapeKey: true,
                                                            showCancelButton: false,
                                                            showConfirmButton: false,
                                                        });
                                                        // resolve();

                                                    } else {
                                                        Swal.showValidationMessage(res.error.message); // Hata mesajı göster
                                                    }
                                                });
                                        }),
                                    });
                                }
                            },
                            Excel: {
                                text: 'Excel',
                                disabled: !selectGroup,
                                click() {
                                    if (!selectGroup) { SwalWarning('Please select a property!'); return; }
                                    exportExcel()
                                }
                            }
                        }
                    }
                />
                <Stack p={2} direction="row" alignItems="center" justifyContent="center" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                    <FooterInfo title="Manager / Receptionist" color='#3788d8' />
                    <FooterInfo title="Maintenancer / Night Porter" color='#54D62C' />
                    <FooterInfo title="House Keeper" color='#FFC107' />
                </Stack>
            </Container>
            <Modal
                open={editOpen}
                onClose={handleEditClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                    zIndex: 1,
                }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" mb={1}>
                        Edit Roster
                    </Typography>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                        <Typography variant="subtitle" >
                            Staff:
                        </Typography>
                        <Typography variant="subtitle" textAlign={'left'}>
                            {event?.title}
                        </Typography>
                    </Stack>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                        <Typography variant="subtitle" >
                            Shift:
                        </Typography>
                        <Typography variant="subtitle" textAlign={'left'}>
                            {event?.extendedProps?.startTime} - {event?.extendedProps?.endTime}
                        </Typography>
                    </Stack>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                        <Typography variant="subtitle" >
                            Total hours:
                        </Typography>
                        <Typography variant="subtitle" textAlign={'left'}>
                            {/* calculate end time minus start time */}
                            {calculateTotalHours(event?.extendedProps?.startTime, event?.extendedProps?.endTime)}
                        </Typography>
                    </Stack>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <FormControl sx={{ m: 1, width: "50%" }}>
                            <TimeField
                                label="Start Time"
                                format="HH:mm"
                                size='small'
                                value={editStartTime}
                                onChange={(value) => setEditStartTime(value)}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, width: "50%" }}>
                            <TimeField
                                label="End Time"
                                format="HH:mm"
                                size='small'
                                value={editEndTime}
                                onChange={(value) => setEditEndTime(value)}
                            />
                        </FormControl>
                    </Stack>
                    <Stack mt={3} direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                        <Button variant="contained" color="error" onClick={handleDelete}>
                            Delete
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleUpdate}>
                            Update
                        </Button>
                    </Stack>
                </Box>
            </Modal>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                    zIndex: 1,
                }}
            >
                <Box sx={style}>
                    <Typography mb={1} id="modal-modal-title" variant="h6" component="h2">
                        Add Staff to Roster
                    </Typography>
                    {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="user-label">User</InputLabel>
                            <Controller
                                name="user"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        labelId="user-label"
                                        id="user"
                                        label="User"
                                        {...field}
                                    >
                                        <MenuItem value="" selected disabled ><em>Select</em></MenuItem>
                                        {
                                            filteredUsers?.map((user, i) => (
                                                <MenuItem key={i} value={user.id}>{`${user.first_name} ${user.last_name} (${user.userprofile?.role})`}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                )}
                            />
                            <FormHelperText style={{ color: 'red' }} >{errors.user?.message}</FormHelperText>
                        </FormControl>
                    </Stack>
                    {selectedDates.map((date, i) => (
                        <Stack key={i} mb={1} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                            <FormControl component="fieldset" sx={{ m: 1, width: "100%" }}>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox checked={!!selectedTimes[date]} onChange={() => handleCheckboxChange(date)} />}
                                        label={date}
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: "50%" }}>
                                <TimeField
                                    label="Start Time"
                                    format="HH:mm"
                                    size='small'
                                    disabled={!selectedTimes[date]}
                                    // value={selectedTimes[date]?.startTime || ''}
                                    onChange={(value) => handleTimeChange(date, 'startTime', value)}
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: "50%" }}>
                                <TimeField
                                    label="End Time"
                                    format="HH:mm"
                                    size='small'
                                    disabled={!selectedTimes[date]}
                                    // value={selectedTimes[date]?.endTime || ''}
                                    onChange={(value) => handleTimeChange(date, 'endTime', value)}
                                />
                            </FormControl>
                        </Stack>
                    ))}
                    {/* </form> */}
                    <Stack mt={2} direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>

                        <Button variant="contained" color="warning" style={{ color: 'white' }} onClick={() => checkHoliday()}>
                            Check Day Off and Holiday
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                            Submit
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default Roster