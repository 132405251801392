import { Alert, Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import DoneIcon from '@mui/icons-material/DoneOutlined';
import Pending from "@mui/icons-material/PendingOutlined";
import LoopIcon from '@mui/icons-material/Loop';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from "react-router-dom";
import { Cancel } from "@mui/icons-material";
import { getIssueRecords } from '../redux/features/api';

export default function IssueRecords() {


    const navigate = useNavigate();
    // const isSuperuser = JSON.parse(localStorage.getItem('userData')).is_superuser
    // priority convert to color
    const priorityColor = {
        'high': 'error',
        'medium': 'warning',
        'low': 'success',
    }

    const TYPE_CHOICES = {
        'general': 'General',
        'heating': 'Heating',
        'plumbing': 'Plumbing',
        'electrical': 'Electrical',
        'carpentry': 'Carpentry',
        'painting': 'Painting',
        'appliance': 'Appliance',
        'other': 'Other',
    }

    const statusColor = {
        pending: '#fbbf24',
        in_progress: '#38bdf8',
        resolved: '#4ade80',
        cancelled: '#a1a1aa',
    };

    const STATUS_CHOICES = {
        pending: 'Pending',
        in_progress: 'In Progress',
        resolved: 'Resolved',
        cancelled: 'Cancelled',
    };

    const statusIcon = {
        pending: <Pending />,
        in_progress: <LoopIcon />,
        resolved: <DoneIcon />,
        cancelled: <Cancel />,
    };

    const statusChip = (row) => (
        <Alert sx={{ width: '100%', padding: "3px", backgroundColor: statusColor[row.status] }} variant="filled" icon={statusIcon[row.status]}>
            {STATUS_CHOICES[row.status]}
        </Alert>
    )

    // const handleAddNew = () => {
    //     navigate('/dashboard/newissue', { replace: true });
    // }



    const [status, setStatus] = useState('')
    const [priority, setPriority] = useState('')
    const [issueType, setIssueType] = useState('')
    const [filteredData, setFilteredData] = useState(null)
    const handleFilter = () => {
        const filteredRows = issueRecords.filter(row => {
            if (status && row.status !== status) {
                return false;
            }

            if (priority && row.priority !== priority) {
                return false;
            }

            if (issueType && row.type !== issueType) {
                return false;
            }

            return true;
        });
        setFilteredData(filteredRows);
    }

    const handleReset = () => {
        setStatus('')
        setPriority('')
        setIssueType('')
        setFilteredData(null)
    }

    // const sortModel = [
    //     {
    //         field: 'date',
    //         sort: 'desc',
    //     },
    // ];
    const columns = [
        {
            flex: 0.1,
            minWidth: 20,
            field: 'priority',
            headerName: 'Priority',
            align: 'center',
            renderCell: ({ row }) => (
                <CircleIcon color={priorityColor[row.priority]} />
            )
        },
        {
            flex: 0.3,
            // width: 300,
            field: 'title',
            headerName: 'Issue Name',
            renderCell: ({ row }) => (
                <Stack direction="row">
                    <Box style={{ whiteSpace: 'pre-wrap' }}>
                        <Typography variant="subtitle2">{row.title}</Typography>
                    </Box>
                </Stack>
            )
        },
        {
            flex: 0.2,
            // minWidth: 200,
            field: 'type_name',
            headerName: 'Issue Type',
            renderCell: ({ row }) => (
                <Stack direction="row">
                    <Typography variant="subtitle2">{TYPE_CHOICES[row.type]}</Typography>
                </Stack>
            )
        },
        {
            flex: 0.2,
            // minWidth: 200,
            field: 'branch',
            headerName: 'Property',
            renderCell: ({ row }) => {
                <Stack direction="row">
                    <Typography variant="subtitle2">
                        {row.branch ? row.branch : 'N/A'}
                    </Typography>
                </Stack>
            }
        },
        {
            flex: 0.2,
            // minWidth: 200,
            field: 'date',
            headerName: 'Created Date',
            renderCell: ({ row }) => {
                const dateObj = new Date(row.date);
                // dd/mm/yyyy HH:mm format
                // const formattedDate = `${dateObj.getDate()}/${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj.getFullYear()} ${dateObj.getHours().toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}`;
                const day = dateObj.getDate().toString().padStart(2, '0');
                const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
                const year = dateObj.getFullYear();
                const hours = dateObj.getHours().toString().padStart(2, '0');
                const minutes = dateObj.getMinutes().toString().padStart(2, '0');

                const dateStyle = {
                    // fontWeight: 'bold',
                };

                const timeStyle = {
                    color: 'gray',
                };

                return (
                    <Stack direction="column" spacing={0.1}>
                        <Typography variant="subtitle2" style={dateStyle}>
                            {`${day}-${month}-${year}`}
                        </Typography>
                        <Typography variant="caption" style={timeStyle}>
                            {`${hours}:${minutes}`}
                        </Typography>
                    </Stack>
                );
            }
        },
        {
            flex: 0.2,
            // minWidth: 20,
            field: 'status',
            headerName: 'Status',
            renderCell: ({ row }) => (
                <Grid container>
                    {
                        statusChip(row)
                    }
                </Grid>
            )
        },
        // made this column hidden
        {
            flex: 0.2,
            // minWidth: 20,
            field: 'description',
            headerName: 'Description',
            visible: true,
            renderCell: ({ row }) => (
                <Stack direction="row">
                    <Typography variant="subtitle2">{row.description}</Typography>
                </Stack>
            )
        },
    ]
    const issueRecordsData = useSelector((state) => state.issueRecords)
    const { isLoading, issueRecords } = issueRecordsData
    const dispatch = useDispatch()
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })

    useEffect(() => {
        dispatch(getIssueRecords())
    }, [dispatch])

    const handleRowClick = (params) => {
        navigate(`/dashboard/issues/${params.row.id}`, { state: { issue: params.row } })
    }
    return (
        <>
            <Helmet>
                <title> Issue Records 2</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Issue Records
                    </Typography>
                    {/* <Button onClick={handleAddNew} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                        Add New
                    </Button> */}
                </Stack>
                <Typography variant="h6">
                    Filter
                </Typography>
                <Box sx={{
                    width: '100%',
                    mb: 2,
                    p: 2,
                    bgcolor: 'background.neutral',
                    borderRadius: 1,
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }}>

                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <InputLabel id="issueType-label">Issue Type</InputLabel>
                                <Select
                                    labelId="issueType-label"
                                    id="issueType"
                                    label="Issue Type"
                                    value={issueType}
                                    onChange={(e) => setIssueType(e.target.value)}
                                    disabled={isLoading}
                                >
                                    <MenuItem value=""><em>All</em></MenuItem>
                                    {
                                        Object.keys(TYPE_CHOICES).map((key, index) => (
                                            <MenuItem key={index} value={key}>{TYPE_CHOICES[key]}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>

                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <InputLabel id="status-label">Status</InputLabel>
                                <Select
                                    labelId="status-label"
                                    id="status"
                                    label="Status"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    disabled={isLoading}
                                >
                                    <MenuItem value=""><em>All</em></MenuItem>
                                    <MenuItem value="in_progress">In Progress</MenuItem>
                                    <MenuItem value="pending">Pending</MenuItem>
                                    <MenuItem value="resolved">Done</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <InputLabel id="priority-label">Priority</InputLabel>
                                <Select
                                    labelId="priority-label"
                                    id="priority"
                                    label="Priority"
                                    value={priority}
                                    onChange={(e) => setPriority(e.target.value)}
                                    disabled={isLoading}
                                >
                                    <MenuItem value="">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <em>All</em>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value="low">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CircleIcon color={priorityColor.low} style={{ marginRight: '8px' }} />
                                            <span>Low</span>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value="medium">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CircleIcon color={priorityColor.medium} style={{ marginRight: '8px' }} />
                                            <span>Medium</span>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value="high">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CircleIcon color={priorityColor.high} style={{ marginRight: '8px' }} />
                                            <span>High</span>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={1.5}  >
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <Button
                                    variant="contained"
                                    sx={{ mt: 0.5 }}
                                    size="large"
                                    color="warning"
                                    onClick={() => handleReset()}
                                    disabled={isLoading}
                                >
                                    Reset
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1.5}  >
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <Button
                                    variant="contained"
                                    sx={{ mt: 0.5 }}
                                    color="primary"
                                    size="large"
                                    onClick={() => handleFilter()}
                                    disabled={isLoading}
                                >
                                    Apply
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>

                </Box>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        autoHeight
                        rowHeight={50}
                        loading={isLoading}
                        rows={filteredData ?? issueRecords}
                        columns={columns}
                        pageSizeOptions={[10, 25, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        // sortModel={sortModel}
                        disableRowSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnMenu
                        onRowClick={handleRowClick}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                showColumnSelector: true,
                                quickFilterProps: { debounceMs: 500 },
                                csvOptions: { allColumns: true },
                            },
                        }}
                        columnVisibilityModel={{
                            description: false,
                        }}

                    />
                </div>
            </Container>
        </>
    )
}

