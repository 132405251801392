import { createSlice } from "@reduxjs/toolkit"
import { authApi } from "./api"

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(authApi.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(authApi.fulfilled, (state, action) => {
                state.isLoading = false
                state.token = action.payload.token
            })
            .addCase(authApi.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    }
})


export default authSlice.reducer

