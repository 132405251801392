import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { apiURL } from "../config"

const token = JSON.parse(localStorage.getItem('jwt'));
const axiosAuth = axios.create({
    baseURL: `${apiURL}/dev/api`,
    headers: {
        Authorization: `Bearer ${token?.access_token}`,
        'Content-Type': 'application/json',
    },
})
axiosAuth.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = '/login'
        }
        return Promise.reject(error);
    }
);
axios.defaults.baseURL = `${apiURL}/dev/api`;

export const authApi = createAsyncThunk('auth/login', async ({ username, password }) => {
    // eslint-disable-next-line no-useless-catch
    try {
        const response = await axios.post('/login/', { username, password })
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }

    }
})
// issue records part

export const getIssueRecords = createAsyncThunk('issueRecords/get', async () => {
    try {
        const response = await axiosAuth.get('/issuerecords/')
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})
export const getUsers = createAsyncThunk('users/get', async () => {
    try {
        const response = await axiosAuth.get('/users/')
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})

export const addUser = createAsyncThunk('users/add', async (data) => {
    try {
        const response = await axiosAuth.post('/users/', { ...data })
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else if (data.error)
                throw new Error(data.error);
            else
                throw new Error(JSON.stringify(data));
        } else {
            throw error;
        }
    }
})
export const getProducts = createAsyncThunk('products/get', async () => {
    try {
        const response = await axiosAuth.get('/products/')
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})

export const addProduct = createAsyncThunk('products/add', async (data) => {
    try {
        const response = await axiosAuth.post('/products/', { ...data })
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else if (data.error)
                throw new Error(data.error);
            else
                throw new Error(JSON.stringify(data));
        } else {
            throw error;
        }
    }
})

export const getProductsCounting = createAsyncThunk('counting/get', async () => {
    try {
        const response = await axiosAuth.get('/inventorycounts/')
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})

export const addProductsCounting = createAsyncThunk('counting/add', async (data) => {
    try {
        const response = await axiosAuth.post('/inventorycounts/', { ...data })
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})

export const sendAnnouncement = createAsyncThunk('announcement/send', async (data) => {
    try {
        const response = await axiosAuth.post('/announcements/', { ...data })
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})

export const getAnnouncements = createAsyncThunk('announcement/get', async () => {
    try {
        const response = await axiosAuth.get('/announcements/')
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})




export const getHolidays = createAsyncThunk('holidays/get', async () => {
    try {
        const response = await axiosAuth.get('/holidays/')
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})
export const updateHoliday = createAsyncThunk('holidays/update', async (data) => {
    try {
        const response = await axiosAuth.patch(`/holidays/${data.id}/`, { ...data })
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})
export const getRoster = createAsyncThunk('roster/get', async () => {
    try {
        const response = await axiosAuth.get('/roster/')
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})

export const updateRoster = createAsyncThunk('roster/update', async (data) => {
    try {
        const response = await axiosAuth.patch(`/roster/${data.id}/`, { ...data })
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})
export const getGroups = createAsyncThunk('groups/get', async () => {
    try {
        const response = await axiosAuth.get('/groups/')
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})

export const addRoster = createAsyncThunk('roster/add', async (data) => {
    try {
        const response = await axiosAuth.post('/roster/', { ...data })
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})
export const deleteRoster = createAsyncThunk('roster/delete', async (data) => {
    try {
        await axiosAuth.delete(`/roster/${data}/`)
        return data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})

export const publishRoster = createAsyncThunk('roster/publish', async (data) => {
    try {
        const response = await axiosAuth.post('/publish/', { ...data })
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})

export const addIssueRecord = createAsyncThunk('issueRecords/add', async (data) => {
    try {
        const response = await axios.post(`${apiURL}issuerecords/`, { ...data })
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})

export const deleteIssueRecord = createAsyncThunk('issueRecords/delete', async (id) => {
    try {
        const response = await axios.delete(`${apiURL}issuerecords/${id}/`)
        return { id, ...response.data }
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})

export const updateIssueRecord = createAsyncThunk('issueRecords/update', async ({ id, name, description }) => {
    try {
        const response = await axios.put(`${apiURL}issuerecords/${id}/`, { name, description })
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})

export const getTraining = createAsyncThunk('training/get', async () => {
    try {
        const response = await axiosAuth.get('/training/')
        return response.data
    } catch (error) {
        if (error.response) {
            const { data } = error.response;
            if (data.detail)
                throw new Error(data.detail);
            else if (data.message)
                throw new Error(data.message);
            else
                throw new Error(data.error);
        } else {
            throw error;
        }
    }
})

export const addTraining = createAsyncThunk('training/add', async data => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('branch', data.branch);
    formData.append('position', data.position);
    const randomNameWithDate = `${Date.now()}-${data.file.name}`;
    formData.append('file', data.file, randomNameWithDate);
    try {
      const response = await axiosAuth.post('/training/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        // eslint-disable-next-line no-shadow
        const {data} = error.response;
        if (data.detail) {
          throw new Error(data.detail);
        } else if (data.message) {
          throw new Error(data.message);
        } else {
          throw new Error(data.error);
        }
      } else {
        throw error;
      }
    }
  });

  export const checkDayOffHoliday = createAsyncThunk('checkDayOffHoliday/get', async ({user, date}) => {
    try {
        const response = await axiosAuth.get(`/check/${user}/${date}/`)
        return response.data
    } catch (error) {
        if (error.response) {
          // eslint-disable-next-line no-shadow
          const {data} = error.response;
          if (data.detail) {
            throw new Error(data.detail);
          } else if (data.message) {
            throw new Error(data.message);
          } else {
            throw new Error(data.error);
          }
        } else {
          throw error;
        }
      }
    });