// component
import { BeachAccess, Person, WorkHistory, AssignmentLate, Inventory, Calculate, Campaign, ModelTraining } from '@mui/icons-material';
import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const userData = localStorage.getItem('userData');
const rosterCreater = JSON.parse(userData)?.roster_creater ?? false;
const isStaff = JSON.parse(userData)?.is_staff ?? false;
const isSuperuser = JSON.parse(userData)?.is_superuser ?? false;
const userRole = JSON.parse(userData)?.role ?? false;
const isManager = userRole === 'manager';
const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
    key: 1
  },
  isStaff && {
    title: 'Announcements',
    icon: <Campaign />,
    path: '/dashboard/announcements',
    key: 2,
  },
  (isSuperuser || isManager || isStaff) && {
    title: 'Holidays',
    path: '/dashboard/holidays',
    icon: <BeachAccess />,
    key: 3
  },
  (isSuperuser || rosterCreater || isStaff) && {
    title: 'Roster',
    path: '/dashboard/roster',
    icon: <WorkHistory />,
    key: 4
  },
  (isSuperuser || isStaff) && {
    title: 'Users',
    path: '/dashboard/users',
    icon: <Person />,
    key: 5
  },
  (isSuperuser || isStaff) && {
    title: 'Products',
    icon: <Inventory />,
    path: '/dashboard/products',
    key: 6
  },
  (isSuperuser || isStaff) && {
    title: 'Counting',
    icon: <Calculate />,
    path: '/dashboard/counting',
    key: 7
  },
  {
    title: 'Issue Records',
    path: '/dashboard/issues',
    icon: <AssignmentLate />,
    key: 8
  },
  (isSuperuser || isStaff) && {
    title: 'Training',
    icon: <ModelTraining />,
    path: '/dashboard/training',
    key: 9
  },
].filter(Boolean);

export default navConfig;
